import { getMaxFractionDigits, numberFormatter, parseNumber, round } from './numericFunctions';

class NumericInput extends HTMLInputElement {
    private readonly formatAs: string;

    private readonly locale: string;

    constructor() {
        super();
        this.formatAs = this.getAttribute('formatAs') ?? 'quantity';
        this.locale = this.getAttribute('locale') ?? this.ownerDocument.documentElement.lang;
        if ('' === this.locale.trim()) {
            throw new Error('Locale is not set');
        }
        this.value = this.format(this.numericValue);
        this.addEventListener('blur', () => this.onBlur());
    }

    onBlur(): void {
        this.value = this.format(this.numericValue);
    }

    get numericValue(): number {
        const { max } = getMaxFractionDigits(this.formatAs);
        return round(parseNumber(this.value, this.locale), max);
    }

    set numericValue(value: number) {
        this.value = numberFormatter(this.formatAs, this.locale).format(value);
    }

    private format(val: number): string {
        return Number.isNaN(val) ? '' : numberFormatter(this.formatAs, this.locale).format(val);
    }
}

customElements.define('numeric-input', NumericInput, { extends: 'input' });
