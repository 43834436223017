import { assert } from '@/Common/lib';

export { numberFormatter, parseNumber, getFractionDigits as getMaxFractionDigits, round };

function numberFormatter(type: string, locale: string): Intl.NumberFormat {
    const { min, max } = getFractionDigits(type);
    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    });
}

function getFractionDigits(type: string): { max: number; min: number } {
    switch (type) {
        case 'quantity': {
            return { max: 3, min: 0 };
        }
        case 'count': {
            return { max: 0, min: 0 };
        }
        case 'percentage': {
            return { max: 2, min: 2 };
        }
        case 'amount': {
            return { max: 2, min: 2 };
        }
        case 'exchangeRate': {
            return { max: 7, min: 7 };
        }
        default:
            throw new Error('Invalid number format type');
    }
}

/**
 * Parses a string to a number using the given locale, returns NaN if the string is not a valid number
 */
function parseNumber(rawValue: string, locale: string): number {
    const groupSeparatorForThisLocale = getGroupSeparatorForTheLocale(locale);
    const decimalSeparatorForThisLocale = getDecimalSeparatorForTheLocale(locale);

    return parseFloat(
        rawValue
            .replace(new RegExp(`\\${groupSeparatorForThisLocale}`, 'g'), '')
            .replace(decimalSeparatorForThisLocale, '.')
    );
}

function round(number: number, maxDecimals: number): number {
    const roundingAid = 10 ** maxDecimals;
    const num = Math.abs(number);
    const correction = 0.5 * Number.EPSILON * num;
    const sign = number >= 0 ? 1 : -1;
    return (Math.round((num + correction) * roundingAid) / roundingAid) * sign;
}

function getGroupSeparatorForTheLocale(locale: string): string {
    const groupSep = Intl.NumberFormat(locale)
        .formatToParts(10000)
        .find((part) => part.type === 'group')?.value;
    assert(groupSep !== undefined, 'Group separator not found');
    return groupSep;
}

function getDecimalSeparatorForTheLocale(locale: string): string {
    const decimalSep = Intl.NumberFormat(locale)
        .formatToParts(1.1)
        .find((part) => part.type === 'decimal')?.value;
    assert(decimalSep !== undefined, 'Decimal separator not found');
    return decimalSep;
}
